import React from 'react'
import Helmet from 'react-helmet'

export default function ({ seo }) {
    const { title = '', url = '', excerpt = '', categories = [] } = seo
    const pattern = /https?\:\/{2}/
    const heroImage = seo.heroImage
        ? pattern.test(seo.heroImage) ? seo.heroImage : `${process.env.GATSBY_URL}/images/300x180/${seo.heroImage}`
        : ''

    return <Helmet>
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="article" />

        {excerpt && <meta name="description" content={excerpt} />}
        {categories.length && <meta name="keywords" content={categories.join(',')} />}

        {title && <meta property="og:title" content={title} />}
        {url && <meta property="og:url" content={url} />}
        {heroImage && <meta property="og:image" content={heroImage} />}
        {excerpt && <meta property="og:description" content={excerpt} />}
    </Helmet>
}