import 'highlight.js/styles/atom-one-dark.css'

import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import { getDevice } from '~/libs/es/device'
import { generateRelateArticles, truncate } from '~/libs/es/helper'
import { RELATE_ARTICLE_SET } from '~/store'

import Seo from 'components/seo'

export default connect(({ relateArticles }) => ({ relateArticles }))(function ({ dispatch, relateArticles = [], data, pageContext }) {
    const [isPhone, setIsPhone] = useState(false)

    useEffect(() => {
        if (getDevice() === 'phone') {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }
    }, [])

    if (!Object.keys(relateArticles).length) {
        const { allArticle: { articles } } = data

        dispatch({
            type: RELATE_ARTICLE_SET,
            payload: generateRelateArticles(articles)
        })
    }

    const { markdownRemark } = data
    const { id, excerpt, html, frontmatter: { title, heroImage, categories } } = markdownRemark

    const { prev, next } = relateArticles[id] ? relateArticles[id] : {}

    const truncateLength = isPhone ? 25 : 35

    return <div className="content is-article">
        <Helmet>
            <title>{pageContext.helmetTitle}</title>
            <link href="https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap" rel="stylesheet" />
        </Helmet>

        <Seo seo={{ excerpt, title, heroImage, categories }} />

        <div dangerouslySetInnerHTML={{ __html: html }}></div>

        <hr />

        <nav className="pagination is-centered is-rounded" style={{ marginBottom: '2em' }}>
            {
                prev && <Link to={prev.to} className="pagination-previous">{'< '}{truncate(prev.title, truncateLength)}</Link>
            }
            {
                next && <Link to={next.to} className="pagination-next">{truncate(next.title, truncateLength)}{' >'}</Link>
            }
        </nav>
    </div>
})

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            excerpt
            html
            frontmatter {
                title
                heroImage
                articleDate
                categories
            }
        }

        allArticle: allSitePage(filter: {path: {regex: "/article/\\w+/"}, context: { title: { ne: null }}}, sort: {fields: context___articleDate, order: DESC}) {
            articles: nodes {
                context {
                    id
                    title
                    to
                }
            }
        }
    }
`